import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/locations.module.scss";
import Imgs from "../components/Imgs";
import Masonry from "react-masonry-css";
import "../assets/css/masonry.css"; /* este css lo creé yo, nose si va acá */
import { Link } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LugaresPage = () => {
	const { nodes: lugares } = useStaticQuery(
		graphql`
			query {
				lugares: allInternalLugares(
					filter: { alternative_id: { ne: null } }
					sort: { fields: title }
				) {
					nodes {
						id: alternative_id
						name
						title
						subtitle
						imagen {
							dataUrl
							height
							url
							width
							description
						}
						imagen_small {
							dataUrl
							height
							url
							width
							description
						}
						intl {
							subtitle {
								ca
								en
								es
								fr
							}
							title {
								ca
								en
								es
								fr
							}
						}
						pistas_total
						remontes_total
						snowparks_total
						centros {
							alternative_id
						}
					}
				}
			}
		`,
	).lugares;

	const [currentSlide, setCurrentSlide] = useState(0);
	const [currentPosition, setCurrentPosition] = useState(0);
	const [sliderMessage, setSliderMessage] = useState(
		lugares[0].imagen.description,
	);
	const [sliderLugarName, setSliderLugarName] = useState("");
	const [sliderCentrosTotal, setSliderCentrosTotal] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (currentPosition < 100) {
				setCurrentPosition(currentPosition + 0.5);
			} else {
				setCurrentPosition(0);
				if (currentSlide < 3) setCurrentSlide(currentSlide + 1);
				else setCurrentSlide(0);
			}
			setSliderMessage(
				lugares[currentSlide].imagen.description ||
					lugares[currentSlide].title,
			);
			setSliderLugarName(lugares[currentSlide].name);
			setSliderCentrosTotal(lugares[currentSlide].centros.length);
		}, 20);
	}, [currentPosition]);

	return (
		<Layout>
			<Seo title="Lugares" />

			<section className={styles.places}>
				<article className={styles.sliderPlaces}>
					<div className={styles.slider}>
						<Carousel
							showArrows={false}
							showStatus={false}
							showIndicators={false}
							infiniteLoop={false}
							showThumbs={false}
							useKeyboardArrows={false}
							autoPlay={false}
							stopOnHover={false}
							swipeable={false}
							dynamicHeight={false}
							emulateTouch={false}
							selectedItem={currentSlide}
						>
							<div className={styles.item}>
								<img
									src={lugares[0].imagen.url}
									alt={lugares[0].title}
								/>
							</div>
							<div className={styles.item}>
								<img
									src={lugares[1].imagen.url}
									alt={lugares[1].title}
								/>
							</div>
							<div className={styles.item}>
								<img
									src={lugares[2].imagen.url}
									alt={lugares[2].title}
								/>
							</div>
							<div className={styles.item}>
								<img
									src={lugares[3].imagen.url}
									alt={lugares[3].title}
								/>
							</div>
						</Carousel>
					</div>
					<div className={styles.placesWrapper}>
						<Link to="/" className="lineLink">
							<u>Volver a la home</u>
						</Link>
						<div className={styles.sliderBullets}>
							<ul>
								<li className={styles.contentHolder}>
									<span
										className={styles.content}
										style={{
											width: `${
												currentSlide > 0
													? "100%"
													: `${
															currentSlide === 0
																? `${currentPosition}%`
																: `0%`
													  }`
											}`,
										}}
									></span>
								</li>
								<li className={styles.contentHolder}>
									<span
										className={styles.content}
										style={{
											width: `${
												currentSlide > 1
													? "100%"
													: `${
															currentSlide === 1
																? `${currentPosition}%`
																: `0%`
													  }`
											}`,
										}}
									></span>
								</li>
								<li className={styles.contentHolder}>
									<span
										className={styles.content}
										style={{
											width: `${
												currentSlide > 2
													? "100%"
													: `${
															currentSlide === 2
																? `${currentPosition}%`
																: `0%`
													  }`
											}`,
										}}
									></span>
								</li>
								<li className={styles.contentHolder}>
									<span
										className={styles.content}
										style={{
											width: `${
												currentSlide > 3
													? "100%"
													: `${
															currentSlide === 3
																? `${currentPosition}%`
																: `0%`
													  }`
											}`,
										}}
									></span>
								</li>
							</ul>
							<div className={styles.sliderTitle}>
								<h3>{sliderMessage}</h3>
								<Link to={`/locations/${sliderLugarName}`}>
									{sliderCentrosTotal === 0 && "Ver centros"}
									{sliderCentrosTotal === 1 && "Ver 1 centro"}
									{sliderCentrosTotal > 1 &&
										`Ver ${sliderCentrosTotal} centros`}
								</Link>
							</div>
						</div>
					</div>
				</article>

				<article className={styles.searchPlaces}>
					<div className={styles.text}>
						<h3>Todos los lugares</h3>
						<p>
							Sorpréndete con los lugares y estaciones que te quedan por
							conocer, descubre los mejores secretos de la montaña y
							disfruta al máximo tu experiencia en la nieve.
						</p>
					</div>
					<form>
						<label htmlFor="country">
							<img src="/lugares/location-icon.svg" alt="" />
							<input
								type="text"
								name="country"
								placeholder="Filtra por país"
							/>
						</label>
						<label htmlFor="skicenter">
							<img src="/lugares/search-icon.svg" alt="" />
							<input
								type="text"
								name="skicenter"
								placeholder="Busca un centro de ski"
							/>
						</label>
					</form>
					<div className={styles.images}>
						<Masonry
							breakpointCols={3}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{lugares.map((lugar, index) => {
								let linkText = "";
								if (lugar.centros.length === 0) {
									linkText = "Ver";
								} else if (lugar.centros.length === 1) {
									linkText = "1 estación";
								} else {
									linkText = `${lugar.centros.length} estaciones`;
								}
								return (
									<div key={index}>
										<Imgs
											title={lugar.title}
											subtitle={lugar.subtitle}
											linkText={linkText}
											linkTo={`/locations/${lugar.name}`}
											imagen={
												lugar.imagen_small && lugar.imagen_small.url
													? lugar.imagen_small
													: null
											}
										/>
									</div>
								);
							})}
						</Masonry>
					</div>
				</article>
			</section>
		</Layout>
	);
};

export default LugaresPage;
